import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TopContent from '../../Shared/Components/TopContent';
import ModalBox from '../../Shared/Components/ModalBox';
import NewContent from './Components/NewCompany';
import DirectCompanyTable from './Components/DirectCompanyTable';
import IndirectCompanyTable from './Components/IndirectCompanyTable';
import { checkPermissionAllow } from '../../utils/helpers';
import DistributorTable from './Components/DistributorTable';

function Company() {
  const menus = [
    {
      key: 'direct',
      enable: checkPermissionAllow('getDirectCompany'),
    },
    {
      key: 'indirect',
      enable: checkPermissionAllow('getIndirectCompany'),
    },
    {
      key: 'distributor',
      enable: checkPermissionAllow('getDistributors'),
    },
  ];
  const { t } = useTranslation();
  const { page } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modelView, setModelView] = useState();

  const clickFunction = () => {
    setOpen(true);
    setModelView({
      title: t('newAccount'),
      content: <NewContent />,
    });
  };

  const closeModal = () => {
    setOpen(false);
  };
  return (
    <div className="main-container">
      <TopContent
        label={t('account')}
        buttonClass="success-button"
        button={checkPermissionAllow('addCmsCompany') || checkPermissionAllow('addDistributorCompany')}
        buttonLabel={t('newAccount')}
        click={clickFunction}
      />
      <ModalBox
        status={open}
        closeModal={closeModal}
        modalView={modelView}
      />
      <div className="content-area">
        <div className="my-content">
          <div className="my-content-area">
            <div className="subscription-menu">
              {
                menus?.map((menu) => menu.enable && (
                  <div
                    role="presentation"
                    className={page === menu.key ? 'each-menu menu-active' : 'each-menu'}
                    onClick={() => navigate(`../${menu.key}`)}
                  >
                    {t(menu.key)}
                  </div>
                ))
              }
            </div>
            {
              page === 'direct' && checkPermissionAllow('getDirectCompany') && <DirectCompanyTable />
            }
            {
              page === 'indirect' && checkPermissionAllow('getIndirectCompany') && <IndirectCompanyTable />
            }
            {
              page === 'distributor' && checkPermissionAllow('getDistributors') && <DistributorTable />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
